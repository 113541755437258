export class Page {    
    filter: string = "";
    size: number = 0;
    totalElements: number = 0;
    totalPages: number = 0;
    pageNumber: number = 0;
    sortColumn: string = "";
    sortDir: string = "asc";
    allData:boolean=false;
    downloadExcel:boolean=false;
}