
//import {Page} from "./page";
import { PageProject } from "app/services/models/page-project";

/**
 * An array of data with an associated page object used for paging
 */
export class PagedData<T> {
    data = new Array<T>();
    page = new PageProject();
    miau;
}