<ul class="card-stack-wrapper">
	<li>
		<div class="card p-b-30">
			<header class="card-header p-15">
				<h2 class="card-title"><ng-content></ng-content></h2>
				<ul class="actions icons top-right">
					<li><ng-content select="small"></ng-content></li>
				</ul>
			</header>
			<div class="card-block h-125">
				<ul>
					<li>
						<ng-content></ng-content>
					</li>
				</ul>
			</div>
		</div>
	</li>
	<li>
		<div class="card p-b-30">
			<header class="card-header p-15">
				<h2 class="card-title"></h2>
				<ul class="actions icons  top-right">
					<li><small class="text-red"><i class="zmdi zmdi-caret-down"></i> 3%</small></li>
				</ul>
			</header>
			<div class="card-block h-125">
				<ul>
					<li>
						
					</li>
				</ul>
			</div>
		</div>
	</li>
	<li>
		<div class="card p-b-30">
			<header class="card-header p-15">
				<h2 class="card-title"></h2>
				<ul class="actions icons  top-right">
					<li><small class="text-green"><i class="zmdi zmdi-caret-up"></i> 23%</small></li>
				</ul>
			</header>
			<div class="card-block h-125">
				<ul>
					<li>
						
					</li>
				</ul>
			</div>
		</div>
	</li>
	<li>
		<div class="card p-b-30">
			<header class="card-header p-15">
				<h2 class="card-title"></h2>
				<ul class="actions icons  top-right">
					<li><small class="text-green"><i class="zmdi zmdi-caret-up"></i> 0.25%</small></li>
				</ul>
			</header>
			<div class="card-block h-125">
				<ul>
					<li>
					
					</li>
				</ul>
			</div>
		</div>
	</li>
</ul>
