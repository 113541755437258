import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PublicService } from './public.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private publicService: PublicService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.publicService.isAuthenticated()
      .then(
      (authenticated: boolean) => {
        if (!authenticated) {
          this.router.navigate(['/', 'login']);
          return false;
        }
        else
          return true;
      }
      );

  }

}
