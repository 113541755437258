import { Page } from "app/services/models/page";

export class PageProject extends Page{
    filterDateStartFrom:string;
    filterDateStartTo: string;
    filterDateContractEnd: string;
    filterActive:boolean;
    filterUserId:string;
    ignoreCurrentUser:boolean;
    isActive:string;
    isPlanificable:string;
    isOcult:string;
    businessUnit:string;
    filterNoRev: boolean;
    filterNoSync: boolean;
    filterOnlyB2B: boolean;
    filterOnlyNoB2B: boolean;
    filterCli: string; 
    filterCliNom: string; 
    filterVen: string; 
    filterVenNom: string; 
    marca: string;
    grup: string;
    subgrup: string;
    tarifa: string;
    venedor: string;
    potenciales: string;
    todosVendedores: string;
    filterUbi: string;
    filterMag: string;
}