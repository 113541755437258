import { Component } from '@angular/core';
//Translate
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.scss']
})
export class BaseComponentComponent {
  
  private msg={
    _login_error:"Error en entrar"
  };

  constructor(
    //public translate: TranslateService
  ) {
    
        //Translate init
        // translate.addLangs(["en", "es"]);
        // translate.setDefaultLang("en");
    
        // let browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
      }
      
      public getString(value: string): string {
    
        let str: string;
    
        // this.translate.get(value).subscribe((res: string) => {
        //   return str = res;
        // });
    
        return str;
      }

      public getMsgDialogs(str){
        return this.msg[str];
      }

}
