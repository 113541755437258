
<div class="footer-content">
	<div class="row">
		<div class="col-xs-12 col-sm-6">
			<h6>Want to Work with Us?</h6>
			<p>Paleo flexitarian bushwick letterpress, ea migas yr adipisicing. Man bun tacos tumblr kombucha, yuccie banjo affogato dolore gentrify retro chartreuse. Anim austin tempor ethical, sapiente food truck fanny pack farm-to-table.
				Culpa keytar esse tilde hoodie, art party nostrud messenger bag authentic helvetica kinfolk cred eu affogato forage.</p>
			</div>
			<div class="col-xs-12 col-sm-2">
				<h6>Company</h6>
				<ul>
					<li><a href="javascript:void(0)">About Us </a></li>
					<li><a href="javascript:void(0)">Careers</a></li>
					<li><a href="javascript:void(0)">Privacy Policy</a></li>
					<li><a href="javascript:void(0)">Contact Us</a></li>
				</ul>
			</div>
			<div class="col-xs-12 col-sm-4">
				<h6>Email Newsletters</h6>
				<p>Sign up for new MaterialWrap content, updates, and offers.</p>
				<div class="form-group p-0 m-0">
					<div class="input-group">
						<label class="control-label sr-only" for="footerEmail">Email Address</label>
						<input type="email" class="form-control m-r-20" id="footerEmail" placeholder="Enter your email address...">
						<span class="input-group-btn">
							<button type="button" class="btn btn-white btn-fab animate-fab btn-fab-sm">
								<i class="zmdi zmdi-mail-send"></i>
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row copy-wrapper">
			<div class="col-xs-8">
				<p class="copy">&copy; Copyright <time class="year"></time> MaterialWrap - All Rights Reserved</p>
			</div>
			<div class="col-xs-4">
				<ul class="social">
					<li>
						<a href="javascript:void(0)"> </a>
					</li>
					<li>
						<a href="javascript:void(0)"> </a>
					</li>
					<li>
						<a href="javascript:void(0)"> </a>
					</li>
					<li>
						<a href="javascript:void(0)"> </a>
					</li>
				</ul>
			</div>
		</div>
	</div>
