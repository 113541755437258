import { HttpClient, HttpHeaders, HttpResponse, HttpClientJsonpModule, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import "rxjs/Rx";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/catch";
@Injectable()
export class DataService {
	constructor(private http: HttpClient) {}

	getListCardDemo = (): Observable<any> => {
		return this.http
			.get("/assets/data/ListCardData.json");
	};
	getMailCardDemo = (): Observable<any> => {
		return this.http
			.get("/assets/data/mail/MailCardData.json");
	};
	getMailDemo = (): Observable<any> => {
		return this.http
			.get("/assets/data/mail/MailData.json");
	};
	addDemoNotes = (): Observable<any> => {
		return this.http
			.get("/assets/data/notes/notes.json");
	};
}
