<div class="card card-blue">
	<header class="card-header">
		<h2 class="card-title">San Diego, CA</h2>
		<p lass="p-0"><span class="curr-dd text-white"></span> <span class="curr-mmmm-dd text-white"></span></p>
	</header>
	<div class="card-block p-t-0">
		<div class="row">
			<div class="col-mat-6">
				<h3 class="text-center font-size-30 text-white">Today</h3>
				<div class="text-center">
					<i class="wi wi-day-sunny font-size-60 m-b-10"></i>
					<h4 class="text-white font-size-20">78°C</h4>
				</div>
			</div>
			<div class="col-mat-6">
				<h3 class="text-center font-size-30 text-white">Tonight</h3>
				<div class="text-center">
					<i class="wi wi-night-alt-lightning font-size-60 m-b-10"></i>
					<h4 class="text-white font-size-20">42°C</h4>
				</div>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<div class="row m-t-40">
			<div class="col-2">
				<div>
					<div class="m-b-10">TUE</div>
					<i class="wi wi-day-sunny font-size-30 m-b-10"></i>
					<div>66°
						<span class="font-size-12">C</span>
					</div>
				</div>
			</div>
			<div class="col-2">
				<div>
					<div class="m-b-10">WED</div>
					<i class="wi wi-day-cloudy font-size-30 m-b-10"></i>
					<div>71°
						<span class="font-size-12">C</span>
					</div>
				</div>
			</div>
			<div class="col-2">
				<div>
					<div class="m-b-10">THU</div>
					<i class="wi wi-day-sunny font-size-30 m-b-10"></i>
					<div>69°
						<span class="font-size-12">C</span>
					</div>
				</div>
			</div>
			<div class="col-2">
				<div>
					<div class="m-b-10">FRI</div>
					<i class="wi wi-day-cloudy-gusts font-size-30 m-b-10"></i>
					<div>62°
						<span class="font-size-12">C</span>
					</div>
				</div>
			</div>
			<div class="col-2">
				<div>
					<div class="m-b-10">SAT</div>
					<i class="wi wi-day-lightning font-size-30 m-b-10"></i>
					<div>59°
						<span class="font-size-12">C</span>
					</div>
				</div>
			</div>
			<div class="col-2">
				<div>
					<div class="m-b-10">SUN</div>
					<i class="wi wi-day-storm-showers font-size-30 m-b-10"></i>
					<div>61°
						<span class="font-size-12">C</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
