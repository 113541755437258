<mat-sidenav-container>
	<mat-sidenav #focusSidenav>
		<aside class="profile-menu">
		<div class="card">
			<header class="card-header alt-heading">
				<div class="profile">
					<h4 class="card-title">MW</h4></div>
				<a href="javascript:void(0)" class="info"><span>username@materialwrap.com</span></a>
			</header>
			<div class="card-block p-0">
				<nav class="submenu">
					<ul>
						<li mat-ripple><a href="javascript:void(0)"><i class="zmdi zmdi-inbox"></i> Inbox</a></li>
						<li mat-ripple><a href="javascript:void(0)"><i class="zmdi zmdi-mail-send"></i> Sent</a></li>
						<li mat-ripple><a href="javascript:void(0)"><i class="zmdi zmdi-archive"></i> Archived</a></li>
						<li mat-ripple><a href="javascript:void(0)"><i class="zmdi zmdi-delete"></i> Trash</a></li>
						<li mat-ripple><a href="javascript:void(0)"><i class="zmdi zmdi-settings"></i> Settings</a></li>
					</ul>
				</nav>
			</div>
		</div>
	</aside>
	</mat-sidenav>
	<div class="card card-mail m-b-0">
		<header class="card-header app_primary_bg mail-heading">
			<ul class="actions icons  top-left">
				<a href="javascript:void(0)" role="button" class="text-white" (click)="focusSidenav.toggle()">
				<i class="zmdi zmdi-menu"></i>
			</a>
			</ul>
			<h2 class="card-title p-l-30 text-white">Inbox</h2>
			<ul class="actions icons top-right">
				<li>
					<a href="javascript:void(0)" class="text-white">
					<i class="zmdi zmdi-refresh-alt"></i>
				</a>
				</li>
			</ul>
		</header>
		<div class="card-block p-0 max-h-500 scrollbar overflow-auto">
			<ul class="list-group p-0">
				<li class="list-group-item" *ngFor="let item of listItems">
					<span class="float-left"><img src="{{item.img}}" alt="" class="rounded-circle max-w-40 m-r-10 "></span>
					<div class="list-group-item-body">
						<div class="list-group-item-heading">{{item.title}}</div>
						<div class="list-group-item-text">{{item.text}}</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="card-footer">
			<ul class="more">
				<li>
					<a href="javascript:void(0)" class="btn btn-default btn-flat" mat-ripple>View More</a>
				</li>
			</ul>
			<ul class="actions icons right">
				<li>
					<button class="btn btn-accent btn-fab" data-toggle="reveal"><i class="zmdi zmdi-edit"></i></button>
				</li>
			</ul>
		</div>
	</div>
</mat-sidenav-container>
