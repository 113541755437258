import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UpdatePhotoService {
  
  private messageSource = new BehaviorSubject<String>("");
  photoChanged = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: string) {    
    this.messageSource.next(message);
  }


}
