 <div class="card schedule-caard">
	<header class="card-header">
		<div class="curr-date">
			<h3 class="curr-dd text-white"></h3>
			<h5 class="curr-mmmm-dd text-white"></h5>
		</div>
		<ul class="actions icons alt-actions top-right">
			<li>
				<a href="javascript:void(0)">
					<i class="zmdi zmdi-refresh-alt"></i>
				</a>
			</li>
		</ul>
		<ul class="actions icons fab-action bottom-right m-b-n-30">
			<li>
				<button type="button" class="btn btn-accent btn-fab"><i class="zmdi zmdi-plus"></i></button>
			</li>
		</ul>
	</header>
	<div class="card-block clear-both p-0 max-h-500 scrollbar">
		<div class="week-container">
			<ul class="week top">
				<li class="day day-names"><span>sun</span></li>
				<li class="day day-names"><span>mon</span></li>
				<li class="day day-names"><span>tue</span></li>
				<li class="day day-names"><span>wed</span></li>
				<li class="day day-names"><span>thu</span></li>
				<li class="day day-names"><span>fri</span></li>
				<li class="day day-names"><span>sat</span></li>
			</ul>
			<ul class="week bottom">
				<li class="day"><span>7</span></li>
				<li class="day"><span>8</span></li>
				<li class="day current point"><span>9</span></li>
				<li class="day"><span>10</span></li>
				<li class="day"><span>11</span></li>
				<li class="day point"><span>12</span></li>
				<li class="day"><span>13</span></li>
			</ul>
		</div>
		<ul class="list-group p-0">
			<li class="list-group-item border-top p-t-20 p-b-20">
				<span class="pull-left"><img src="assets/img/profiles/01.jpg" alt="" class="rounded-circle max-w-50 m-r-10"></span>
				<div class="list-group-item-body">
					<div class="list-group-item-heading">Design meeting with Melissa</div>
					<div class="list-group-item-text">8:00 - 10:30am</div>
				</div>
			</li>
			<li class="list-group-item border-top p-t-20 p-b-20">
				<span class="pull-left"><img src="assets/img/profiles/04.jpg" alt="" class="rounded-circle max-w-50 m-r-10"></span>
				<div class="list-group-item-body">
					<div class="list-group-item-heading">Review mockups with Janet </div>
					<div class="list-group-item-text">10:15 - 11:30am Hangouts</div>
				</div>
			</li>
			<li class="list-group-item border-top p-t-20 p-b-20">
				<span class="pull-left"><img src="assets/img/profiles/18.jpg" alt="" class="rounded-circle max-w-50 m-r-10"></span>
				<div class="list-group-item-body">
					<div class="list-group-item-heading">Catch up with Michael</div>
					<div class="list-group-item-text">8:30pm Lost Forty Brewing</div>
				</div>
			</li>
		</ul>
	</div>
</div>
