import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponentComponent } from "app/pages/base/base-component/base-component.component";
import { TranslateService } from "@ngx-translate/core";
import { PublicService } from "app/services/public.service";
import { Dialogs } from "app/common/dialogs";
import { FormGroup, FormBuilder } from '@angular/forms';
import swal from 'sweetalert2';
import { Subscription } from "rxjs";


@Component({
  selector: ".content_inner_wrapper",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})

export class LoginComponent extends BaseComponentComponent implements OnDestroy {


  private subscription: Subscription;
  public loading: boolean = false;
  public emailRecover: string;
  public toggleRegister: boolean = false;

  public formData: FormGroup;


  constructor(public translate: TranslateService, private publicService: PublicService, private router: Router, private dialogs: Dialogs, private _formBuilder: FormBuilder) {
    super();
    this.onCreateForm();
  }

  onCreateForm() {
    this.formData = this._formBuilder.group(
      {
        email: "",
        password: "",        
      }
    );
  }

  onSubmit(formData) {
    this.setLoading(true);
    const email = formData.email;
    const password = formData.password;
    this.subscription = this.publicService.login(email, password)
      .subscribe(
      data => {
        // console.log("data.isExtrasAvailable",data.isExtrasAvailable.toString());
        if (data['success']){
          var info = data['data'];
          console.log(info);
          if(info.actiu == "0"){
            this.dialogs.showError(super.getMsgDialogs("_login_error"), "Usuario no activo, contacte con su responsable.");
            return;
          }
          this.setLoading(false);
          this.publicService.setAccessToken(info.token);
          this.publicService.setName(info.name);
          this.publicService.setEMail(info.email);
          this.publicService.setClaim(info.claim);
          this.publicService.setDBName(info.dbname);
          this.publicService.setDBUser(info.dbuser);
          this.publicService.setDBPass(info.dbpass);
          this.publicService.setUserId(info.userid);
          this.publicService.setEmpresa(info.empresa);
          /* paràmetres usuari sga*/
          this.publicService.setMagXdef(info.magXdef);
          this.publicService.setUbiXdef(info.ubiXdef);
          this.publicService.setOcultUd(info.ocultarUnidades);
          this.publicService.setOcultCj(info.ocultarCajas);
          this.publicService.setOcultQt(info.ocultarCantidad);
          this.publicService.setOcultDataCad(info.ocultarDataCaduc);
          this.publicService.setOcultLots(info.ocultarLots);
          this.publicService.setOcultPreuArt(info.ocultarPreuArt);
          this.publicService.setOcultStockArt(info.ocultarSotckArt);
          this.publicService.setMesosAntMovims(info.mesosAntMovims);
          this.publicService.setOcultNoHab(info.ocultarNoHabituals);
        }
        this.router.navigate(['/','stocks']);
        /*
        this.publicService.setVenedor(data.venedor);
        this.publicService.setClient(data.client);
        this.publicService.setClientesPotenciales(data.clientesPotenciales);
        this.publicService.setClientesTodosVendedores(data.clientesTodosVendedores);
        this.publicService.setEstocArt(data.estocArt); 
        this.publicService.setAgruparArtXMarca(data.agruparArtXMarca); 
        this.publicService.setAgruparArtXGrupo(data.agruparArtXGrupo);
        this.publicService.setOrdenarArtConsXCod(data.ordenarArtConsXCod);
        this.publicService.setTarifa(data.tarifa);
        this.publicService.setPreciosInferiores(data.preciosInferiores);
        this.publicService.setOcultarPrecios(data.ocultarPrecios);
        this.publicService.setModificarPrecios(data.modificarPrecios);
        this.publicService.setPermitirCobros(data.permitirCobros);
        this.publicService.setCobrosSinCerrar(data.cobrosSinCerrar);
        this.publicService.setOnlyBotiga(data.onlyBotiga);
        this.publicService.setTimeClear(data.timeClearBotiga);
        */

      },
      error => {
        this.router.navigate(['/','stocks']);
        console.log(error);

        this.setLoading(false);
        if (error.statusText == "Bad Request") {
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "Usuario o contraseña incorrectos!");
        }
        else if (error.statusText == "Empresa deshabilitada") {
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "Empresa deshabilitada, contacte con G2Software");
        } else {
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "");
        }
      }
      );
  }

  closeLeftSideBar(){
      $(".app_sidebar-left-open").removeClass("app_sidebar-left-open");
      $(".appBackdrop").removeClass("appBackdrop");
  }

  private setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public isEmptyText() {
    return this.emailRecover == "" ? true : false;
  }

  public onStartRecoveryPassword() {
    const sub = this.publicService.StartResotrePassword(this.emailRecover)
      .subscribe(
      data => {
        // console.log(data);
        this.dialogs.showItemUploaded("Email enviat", this.getString("CHECK_EMAIL"), "", false).then(
          function () {
            document.getElementById("btnModal").click();
          }, function (dissmis) { }
        )
      },
      error => {
        if (error.statusText=="Unknown Error") {
          this.dialogs.showItemUploaded("E-mail enviat!", "");
        } else {
          this.dialogs.showError("Error en enviar el email", error.statusText);
        }
      },
      () => { }
      );

    if (this.subscription != undefined) {
      this.subscription.add(sub);
    } else {
      this.subscription = sub;
    }
  }

  resendEmail(){
    var miau = this.emailRecover;
    this.publicService.getToken(this.emailRecover).subscribe(
      data => {
          console.log(this.emailRecover);
          console.log(this.emailRecover);
          this.resendNewEmail(this.emailRecover,data);
      },
      error => {       
      }
    );
  }
  resendNewEmail(data,token) {
    this.publicService.resendPassword(data,token).subscribe(
      data => {
        this.dialogs.showItemUploaded("Recuperar contraseña", "Se ha enviado el email de recuperación correctamente.", "/");
      },
      error => {
        this.dialogs.showError("Error", "Ha habido un error al enviar el email de recuperación.");
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

}
