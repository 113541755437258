
<div class="card image-over-card">
	<div class="card-image">
		<a href="javascript:void(0)">
			<img src="{{imgPath}}" />
		</a>
	</div>
	<div class="card-block">
		<h4 class="card-title card-title {{align}} m-b-0 m-t-10">{{title}}</h4>
		<h6 class="category text-gray {{align}}">{{subtitle}}</h6>
		<ng-content select="footer"></ng-content>
	</div>
</div>
