import { Injectable } from '@angular/core';

//const themeA = require('../../styles/themes/theme-a.scss');
//const themeD = require('../../styles/themes/theme-d.scss');
//const themeE = require('../../styles/themes/theme-e.scss');
//const themeH = require('../../styles/themes/theme-h.scss');
const themeDefault = require('../../styles/themes/theme-default.scss');

@Injectable()
export class ThemesService {

    styleTag: any;
    defaultTheme: string = 'default';

    constructor() {
        this.createStyle();
    }

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
			
    }

     setTheme(name) {
        this.injectStylesheet(themeDefault);
    }

    injectStylesheet(css) {
        this.styleTag.innerHTML = css;
    }

    getDefaultTheme() {
        return this.defaultTheme;
    }

}
