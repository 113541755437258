
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { BaseService } from "./base.service";
import { HttpParams } from "@angular/common/http";
//own
import { Session } from "./models/session.model";
import { CookieService } from 'ng2-cookies'
import { HttpHeaders } from "@angular/common/http";

const COOKIE_NAME_ACCESS_TOKEN: string = "accessToken";
const COOKIE_NAME_CLAM_ID: string = "claimId";
const COOKIE_NAME_AVATAR_HASH: string = "avatarHash";
const COOKIE_NAME_USER_NAME: string = "userName";
const COOKIE_NAME_NAME: string = "name";
const COOKIE_NAME_EMAIL: string = "eMail";
const COOKIE_CURRENT_USER_ID: string = "CurrentUserId";
const COOKIE_EMPRESA: string = "empresa";
const COOKIE_NAME_IS_CONTRACT_ACTIVE: string = "isContractActive";

const CLAIM_SUPER_ADMIN: string = "sa";
const CLAIM_ADMIN: string = "admin";
const CLAIM_STD: string = "std";

const TOKEN_TYPE: string = "PROMOTOR-AES-1 ";

/* PARÀMETRES USUARI SGA */
const COOKIE_MAGXDEF: string = "MAGXDEF";
const COOKIE_UBIXDEF: string = "UBIXDEF";
const COOKIE_OCULTARUD: string = "OCULTARUD";
const COOKIE_OCULTARCJ: string = "OCULTARCJ";
const COOKIE_OCULTARQT: string = "OCULTARQT";
const COOKIE_OCULTARDATACAD: string = "OCULTARDATACAD";
const COOKIE_OCULTARLOTS: string = "OCULTARLOTS";
const COOKIE_OCULTARPREUART: string = "OCULTARPREUART";
const COOKIE_OCULTARSTOCKART: string = "OCULTARSTOCKART";
const COOKIE_MESOSANT: string = "MESOSANT";
const COOKIE_OCULTARNOHABT: string = "OCULTARNOHAB";

@Injectable()
export class PublicService extends BaseService {

  constructor(private httpClient: HttpClient,
    private cookieService: CookieService) {
    super();
  }

  protected getBasePath(): string {
    return super.getBasePath() + "";
  }
    protected getahBasePath(): string {
    return super.getBasePath() + "getToken/";
  }
  protected getaiBasePath(): string {
    return super.getBasePath() + "sendRecoverMail/";
  }
  protected getfBasePath(): string {
    return super.getBasePath() + "getLogoCompanie/";
  }

  public _GetBasePath() {
    return super.getBasePath();
  }
  //methods user
  public login(email: string, password: string): Observable<Session> {
    //  console.log(this.getBasePath())
    var body = JSON.parse('{"user":"'+email+'","pass":"'+password+'"}');
    var url = this.getBasePath() + "login";
    //console.log("looooogiiiiin " + url);
    //console.log(body);
    //console.log(this.httpClient.post<Session>(url, body));
    return this.httpClient.post<Session>(url, body);
  }

  public CompleteResotrePassword(password: string, passwordRep: string, token: string) {

    var body = {
      newPassword: password,
      newPassword2: passwordRep,
      token: token,
    }

    var url = this.getBasePath() + "completeRestore/";
    return this.httpClient.post(url, body, { responseType: 'text' });
  }

  public CompleteRegister(password: string, passwordRep: string, token: string) {
    var body = {
      newPassword: password,
      newPassword2: passwordRep,
      token: token,
    }

    var url = this.getBasePath() + "CompleteRegister/";
    return this.httpClient.post(url, body, { responseType: 'text' });
  }

  public getToken(email){
    let url = this.getahBasePath();
    var body = JSON.parse('{"email":"'+email+'"}');
    return this.httpClient.post(url, body);
  }

    public resendPassword(email,token){
    let url= this.getaiBasePath();
    var body = JSON.parse('{"email":"'+email+'","token":"'+token+'"}');
    return this.httpClient.post(url, body);
  }

  public StartResotrePassword(email: string) {
    var url = this.getBasePath() + "RestorePassword/";
    let p: HttpParams = new HttpParams().set("email", email);
    let h: HttpHeaders = new HttpHeaders({ contentType: 'text' });

    return this.httpClient.get(url, { headers: h, params: p, responseType: 'text' });
  }

  public SendMassiveEmails(emails) {
    var url = this.getBasePath() + emails;
    //let p: HttpParams = new HttpParams().set("email", email);
    //let h: HttpHeaders = new HttpHeaders({ contentType: 'text' });

    return this.httpClient.get(url, { responseType: 'text' });
  }

  public getAvatar(avatarHash: string): string {
    return this.getBasePath() + "/GetAvatar?avatarHash=" + avatarHash;
  }

  //methods Cookies
  private removeAllCookies() {
    this.cookieService.deleteAll();
  }

  public logout() {
    this.removeAllCookies();
  }

  public setAccessToken(accessToken: string) {
    this.cookieService.set(COOKIE_NAME_ACCESS_TOKEN, accessToken);
  }

  public getAccessToken(): string {
    return this.cookieService.get(COOKIE_NAME_ACCESS_TOKEN);
  }

  public getTokenType(): string {
    return TOKEN_TYPE;
  }

  public setClaim(claim: string) {
    this.cookieService.set(COOKIE_NAME_CLAM_ID, claim);
  }

  public isStandard(): boolean {
    return this.getClamId() == CLAIM_STD ? true : false;
  }

  public isAdmin(): boolean {
    return this.getClamId() == CLAIM_ADMIN ? true : false;
  }

  public isSuperAdmin(): boolean {
    return this.getClamId() == CLAIM_SUPER_ADMIN ? true : false;
  }

  public getClamId(): string {
    return this.cookieService.get(COOKIE_NAME_CLAM_ID);
  }

  public setAvatarHash(hash: string) {
    this.cookieService.set(COOKIE_NAME_AVATAR_HASH, hash);
  }

  public getAvatarHash(): string {
    return this.cookieService.get(COOKIE_NAME_AVATAR_HASH);
  }

  public setUserName(username: string) {
    this.cookieService.set(COOKIE_NAME_USER_NAME, username);
  }

  public getUserName(): string {
    return this.cookieService.get(COOKIE_NAME_USER_NAME);
  }

  public setName(name: string) {
    this.cookieService.set(COOKIE_NAME_NAME, name);
  }

  public getName(): string {
    return this.cookieService.get(COOKIE_NAME_NAME);
  }

  public setEmpresa(empresa: string) {
    this.cookieService.set(COOKIE_EMPRESA, empresa);
  }

  public getEmpresa() {
    return this.cookieService.get(COOKIE_EMPRESA);
  }


  public setEMail(mail: string) {

    this.cookieService.set(COOKIE_NAME_EMAIL, mail);
  }

  public getEMail(): string {
    return this.cookieService.get(COOKIE_NAME_EMAIL);
  }

  public setIsContractActive(isContractActive: string) {
      
      this.cookieService.set(COOKIE_NAME_IS_CONTRACT_ACTIVE, isContractActive);
      //console.log(isContractActive)
  }

  public getIsContractActive(): string {
     // console.log("isActiveEntra");
      //console.log(COOKIE_NAME_IS_CONTRACT_ACTIVE);
    return this.cookieService.get(COOKIE_NAME_IS_CONTRACT_ACTIVE);
  }


  public setCurrnetUserId(idCurrentUser: string) {
    this.cookieService.set(COOKIE_CURRENT_USER_ID, idCurrentUser);
  }

  public getCurrnetUserId():string {
    return this.cookieService.get(COOKIE_CURRENT_USER_ID);
  }

  //new

  public setDBName(name: string) {
    this.cookieService.set('myDBName', name);
  }

  public getDBName(): string {
    return this.cookieService.get('myDBName');
  }

  public setDBUser(user: string) {
    this.cookieService.set('myDBUser', user);
  }

  public getDBUser(): string {
    return this.cookieService.get('myDBUser');
  }

  public setDBPass(pass: string) {
    this.cookieService.set('myDBPass', pass);
  }

  public getDBPass(): string {
    return this.cookieService.get('myDBPass');
  }

  public setUserId(userid: string) {
    this.cookieService.set('userId', userid);
  }

  public getUserId(): string {
    return this.cookieService.get('userId');
  }

  /* PARÀMETRES USUARI SGA */
  public setMagXdef(magXdef: string) {
    this.cookieService.set('MAGXDEF', magXdef);
  }
  public getMagXdef(): string {
    return this.cookieService.get('MAGXDEF');
  }

  public setUbiXdef(ubiXdef: string) {
    this.cookieService.set('UBIXDEF', ubiXdef);
  }
  public getUbiXdef(): string {
    return this.cookieService.get('UBIXDEF');
  }

  public setOcultUd(hideUd: string) {
    this.cookieService.set('OCULTARUD', hideUd);
  }
  public getOcultUd(): string {
    return this.cookieService.get('OCULTARUD');
  }

  public setOcultCj(hideCj: string) {
    this.cookieService.set('OCULTARCJ', hideCj);
  }
  public getOcultCj(): string {
    return this.cookieService.get('OCULTARCJ');
  }

  public setOcultQt(hideQt: string) {
    this.cookieService.set('OCULTARQT', hideQt);
  }
  public getOcultQt(): string {
    return this.cookieService.get('OCULTARQT');
  }

  public setOcultDataCad(hideDataCad: string) {
    this.cookieService.set('OCULTARDATACAD', hideDataCad);
  }
  public getOcultDataCad(): string {
    return this.cookieService.get('OCULTARDATACAD');
  }

  public setOcultLots(hideDataCad: string) {
    this.cookieService.set('OCULTARLOTS', hideDataCad);
  }
  public getOcultLots(): string {
    return this.cookieService.get('OCULTARLOTS');
  }

  public setOcultPreuArt(preuart: string) {
    this.cookieService.set('OCULTARPREUART', preuart);
  }
  public getOcultPreuArt(): string {
    return this.cookieService.get('OCULTARPREUART');
  }

  public setOcultStockArt(stockArt: string) {
    this.cookieService.set('OCULTARSTOCKART', stockArt);
  }
  public getOcultStockArt(): string {
    return this.cookieService.get('OCULTARSTOCKART');
  }

  public setMesosAntMovims(mesos: string) {
    this.cookieService.set('MESOSANT', mesos);
  }
  public getMesosAntMovims(): string {
    return this.cookieService.get('MESOSANT');
  }

  public setOcultNoHab(flagNoHab: string) {
    this.cookieService.set('OCULTARNOHAB', flagNoHab);
  }
  public getOcultNoHab(): string {
    return this.cookieService.get('OCULTARNOHAB');
  }


  //new

  public isAuthenticated(): Promise<any> {
    return new Promise(
      (resolve, reject) => {
        resolve(this.getAccessToken() != null && this.getAccessToken() != "")
      }
    );
  }

  public getLogoCompanie(dbName){
    let url= this.getfBasePath();
    var body = JSON.parse('{"dbname":"'+dbName+'"}');
    return this.httpClient.post(url, body);
  }

}
