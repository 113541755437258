import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LoginComponent } from "./login.component";
import { SharedModule } from "../../../shared/shared.module";
import { TranslateModule } from '@ngx-translate/core';
import { LogoutComponent } from "app/pages/public/login/logout/logout.component";

const LOGIN_ROUTE = [
  {
    path: "", component: LoginComponent
  },
  {
    path: 'logout', component: LogoutComponent
  },
];

@NgModule({
  declarations: [LoginComponent, LogoutComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(LOGIN_ROUTE), TranslateModule]
})

export class LoginModule { }
