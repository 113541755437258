import { HttpHeaders } from "@angular/common/http";
import { BaseService } from "./base.service";
import { PublicService } from "./public.service";

export class BaseAuthService extends BaseService {

  constructor(public publicService: PublicService) {
    super();
  }

  protected getHeaders(): HttpHeaders {

    return new HttpHeaders().
      set('Authorization', 'PROMOTOR-AES-1 ' + this.publicService.getAccessToken());
  }

}
