import { Injectable, isDevMode } from '@angular/core';

export class BaseService {

    private _apiURL: string;

    constructor() {
            //this._apiURL = "https://lekmo.com/proves/wsProves/"; 
            this._apiURL = "https://lekmo.com/G2SGA/WS/"; 
    }

    protected getAPIURL(): string {
        return this._apiURL;
    }

    protected getBasePath(): string {
        return this.getAPIURL() + "index.php/";
    }

}
