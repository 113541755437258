import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';

import { BaseAuthService } from "./base-auth.service";
import { PublicService } from "./public.service";

import { Page } from "./models/page";
import { PagedData } from "./models/paged-data";
import { ServerResponse } from "./models/server-response.model";
import { PageProject } from "app/services/models/page-project";

@Injectable()
export class DataService<T> extends BaseAuthService {

    private data: ServerResponse<T>;
    public dataUpdated = new EventEmitter<PagedData<T>>();

    constructor(public publicService: PublicService, private httpClient: HttpClient) {
        super(publicService);
    }
 
    public getData(method: string, filter: string, marca: string, grup, subgrup, filterDateContractEnd, limit: number, offset: number, 
        dir: string, orderCol: string, ignoreCurrentUser: boolean, isActive: string, isPlanificable: string, isOcult: string, businessUnit: string, 
        filterNoRev: boolean, filterNoSync: boolean, filterB2B: boolean, filterNoB2B: boolean, filterCli: string,  filterVen: string, 
        filterDateStartFrom: string, filterDateStartTo: string, filterUbi: string, filterMag: string, downloadExcel:boolean): Observable<ServerResponse<T>> {
        //console.log("getdata", filterDateContractEnd);
        var url = this.getBasePath() + method + "/";

        if(!limit){ limit = 25;}


        var dbname = this.publicService.getDBName();
        var dbuser = this.publicService.getDBUser();
        var dbpass = this.publicService.getDBPass();
        var userId = this.publicService.getUserId();

        var body = JSON.parse('{"db_name":"'+dbname+'","db_username":"'+dbuser+'","db_password":"'+dbpass+'","marca":"'+marca+'","grup":"'+grup+'","subgrup":"'+subgrup+'","userid":"'+userId+'","NoRev":"'+filterNoRev+'","NoSync":"'+filterNoSync+'","onlyB2B":"'+filterB2B+'","onlyNoB2B":"'+filterNoB2B+'","filtrCli":"'+filterCli+'","filtrVen":"'+filterVen+'","orderCol":"'+orderCol+'","dir":"'+dir+'","filtrFrom":"'+filterDateStartFrom+'","filtrTo":"'+filterDateStartTo+'","filterUbi":"'+filterUbi+'","filterMag":"'+filterMag+'","downloadExcel":"'+downloadExcel+'"}');

        return this.httpClient.post<any>(url, body/*{ headers: this.getHeaders(), params: params, body: body}*/);
    }

    public getResults(method: string, page: PageProject): void {
        if (page.allData) {
            this.getData(method, page.filter, page.marca, page.grup, page.subgrup, page.filterDateContractEnd, page.totalElements, 0, page.sortDir, page.sortColumn, page.ignoreCurrentUser, page.isActive, page.isPlanificable, page.isOcult, page.businessUnit, page.filterNoRev, page.filterNoSync, page.filterOnlyB2B, page.filterOnlyNoB2B, page.filterCli, page.filterVen, page.filterDateStartFrom, page.filterDateStartTo, page.filterUbi, page.filterMag, page.downloadExcel)
                .subscribe(
                data => {
                    this.data = data;    
                    this.dataUpdated.emit(this.getPagedData(page));
                },
                error => {}
                );
        } else {
            this.getData(method, page.filter, page.marca, page.grup, page.subgrup, page.filterDateContractEnd, page.size, page.pageNumber * page.size, page.sortDir, page.sortColumn, page.ignoreCurrentUser, page.isActive, page.isPlanificable, page.isOcult, page.businessUnit, page.filterNoRev, page.filterNoSync, page.filterOnlyB2B, page.filterOnlyNoB2B, page.filterCli, page.filterVen, page.filterDateStartFrom, page.filterDateStartTo, page.filterUbi, page.filterMag, page.downloadExcel)
                .subscribe(
                data => {
                    this.data = data;
                    //if(this.data.totalRows == undefined &&! this.data[0]){}else{
                         this.dataUpdated.emit(this.getPagedData(page));
                    //}
                   
                },
                error => { }
                );
        }
    }

    private getPagedData(page: PageProject): PagedData<T> {
        let pagedData = new PagedData<T>();
        if (this.data != null) {
            page.pageNumber = page.pageNumber;
            page.size = this.data.limit;
            this.data.totalRows = Object.keys(this.data.data).length;
            page.totalElements = this.data.totalRows;
            page.totalPages = page.totalElements / page.size;
            pagedData.data = this.data.data;
            pagedData.miau = this.data.data;
        }
        pagedData.page = page;
        return pagedData;
    }
    public delete(method: string, id: string) {
        var url = this.getBasePath() + method + "/" + id;
        return this.httpClient.delete(url, { headers: this.getHeaders(), responseType: 'text' });
    }

}
