//DEFAULT PKG
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppState, InternalStateType } from "./app.service";
import { GlobalState } from "./app.state";
import { ServicesModule } from "./shared/services/services.module";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { HttpClientModule } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//OWN PKG
import { CookieService } from "ng2-cookies";
//Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Dialogs } from './common/dialogs';
import { LoginModule } from "app/pages/public/login/login.module";

import { BaseDatatableComponent } from './pages/base/base-datatable/base-datatable.component';
import { BaseComponentComponent } from './pages/base/base-component/base-component.component';

import { DataService } from "./services/data.service";
import { PublicService } from "./services/public.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { UpdatePhotoService } from "app/services/update-photo.service";
import { UserStdService } from 'app/services/user-std.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DeviceDetectorModule } from 'ngx-device-detector';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

// Application wide providers
const APP_PROVIDERS = [
	AppState,
	GlobalState,
	Title,
	{ provide: LocationStrategy, useClass: HashLocationStrategy },
	CookieService,
	DataService,
	PublicService,
	AuthGuardService,
	UserStdService,
	Dialogs,
	UpdatePhotoService,
	{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
];

export type StoreType = {
	state: InternalStateType;
	restoreInputValues: () => void;
	disposeOldHosts: () => void;
};

@NgModule({
	declarations: [
		AppComponent,
		BaseDatatableComponent,
		BaseComponentComponent,
	],

	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		ServicesModule,
		NgbModule,
		DeviceDetectorModule,
		SharedModule.forRoot(),
		AppRoutingModule,
		LoginModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		
		// AgmCoreModule.forRoot({
  //     	apiKey: 'YOUR GOOGLE MAPS API KEY'  apiKey is required, unless you are a premium customer, in which case you can use clientId  
  //   	}),

	],
	providers: [APP_PROVIDERS,MatDatepickerModule],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(public appState: AppState) { }
}
