import { Injectable } from '@angular/core';
import { PublicService } from 'app/services/public.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { User } from 'app/services/models/user';
import { UserBaseService } from 'app/services/user-base.service';

@Injectable()
export class UserStdService extends UserBaseService {

  constructor(public publicService: PublicService, public httpClient: HttpClient) {
    super(publicService, httpClient);
  }

  protected getBasePath(): string {
    return super.getBasePath() + "Std/";
  }
  protected getaaBasePath(): string {
    return super.getaBasePath() + "listEmpresesANDusersG2/";
  }
  protected getabBasePath(): string {
    return super.getaBasePath() + "newOperari/";
  }
  protected getacBasePath(): string {
    return super.getaBasePath() + "getUserSGA/";
  }
  protected getaccBasePath(): string {
    return super.getaBasePath() + "getUserCli/";
  }
  protected getacdasePath(): string {
    return super.getaBasePath() + "updateUser/";
  }
  protected getaeBasePath(): string {
    return super.getaBasePath() + "deleteUser/";
  }
  protected getaeeBasePath(): string {
    return super.getaBasePath() + "deleteUserCli/";
  }
  protected getafBasePath(): string {
    return super.getaBasePath() + "sendConfirmationEmail/";
  }
  protected getagBasePath(): string {
    return super.getaBasePath() + "checkEmail/";
  }
  protected getahBasePath(): string {
    return super.getaBasePath() + "getToken/";
  }
  protected getaiBasePath(): string {
    return super.getaBasePath() + "sendRecoverMail/";
  }
  protected getajBasePath(): string {
    return super.getaBasePath() + "newOperariControl/";
  }
  protected geta2BasePath(): string {
    return super.getaBasePath() + "newUserCli/";
  }
  protected getadBasePath(): string {
    return super.getaBasePath() + "updateUserControl/";
  }
  protected getazBasePath(): string {
    return super.getaBasePath() + "tarifesByCompany/";
  }
  protected getfBasePath(): string {
    return super.getaBasePath() + "deleteLogoComp/";
  }
  protected getgBasePath(): string {
    return super.getaBasePath() + "getClientsWOUser/";
  }
  protected gethBasePath(): string {
    return super.getaBasePath() + "getUsuaris/";
  }

  public putUserStd(id: string, data){
    let url = this.getacdasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'","email":"'+data.email+'","rol":"'+data.rol+'","actiu":"'+data.isActive+'"}');
    console.log(body);
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public putUserStdControl(id: string, data){
    let url = this.getadBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'","email":"'+data.email+'","rol":"'+data.rol+'","actiu":"'+data.isActive+'","name":"'+data.operariControl+'"}');
    console.log(body);
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public postUserStd(dbname,dbuser,dbpass,codiG2,rol,email,userId,codiEmp,password){
    let url = this.getabBasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","codiUserG2":"'+codiG2+'","email":"'+email+'","rol":"'+rol+'","userId":"'+userId+'","codiEmp":"'+codiEmp+'","password":"'+password+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public postUserB2B(dbname,dbuser,dbpass,idVenedor,rol,email,userId){
    let url = this.geta2BasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","idVen":"'+idVenedor+'","email":"'+email+'","rol":"'+rol+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public postUserStdControl(dbname,dbuser,dbpass,nomOperari,rol,email,userId){
    let url = this.getajBasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","nomOperari":"'+nomOperari+'","email":"'+email+'","rol":"'+rol+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public checkEmail(email){
    let url = this.getagBasePath();
    var body = JSON.parse('{"email":"'+email+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getToken(email){
    let url = this.getahBasePath();
    var body = JSON.parse('{"email":"'+email+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public sendConfirmation(email,token){
    let url= this.getafBasePath();

    var body = JSON.parse('{"email":"'+email+'","token":'+token+'}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public resendPassword(email,token){
    let url= this.getaiBasePath();

    var body = JSON.parse('{"email":"'+email+'","token":'+token+'}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getUser(id:string):Observable<User>{
    let url= this.getBasePath() + id;
    return this.httpClient.get<User>(url,{headers:this.getHeaders()});
  }

  public getUserSGA(userId){
    let url= this.getacBasePath();
    var dbname = this.publicService.getDBName();
    var dbuser = this.publicService.getDBUser();
    var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getUserB2B(userId){
    let url= this.getaccBasePath();
    var dbname = this.publicService.getDBName();
    var dbuser = this.publicService.getDBUser();
    var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public deleteUserStd(id){
    let url= this.getaeBasePath();
    var dbname = this.publicService.getDBName();
    var dbuser = this.publicService.getDBUser();
    var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public deleteUserB2B(id){
    let url= this.getaeeBasePath();
    var dbname = this.publicService.getDBName();
    var dbuser = this.publicService.getDBUser();
    var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getInfoUsersEmp(dbname,dbuser,dbpass){
    let url= this.getaaBasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  public getClientsWOUser(dbname,dbuser,dbpass){
    let url= this.getgBasePath();
    var body = JSON.parse('{"db_name":"'+dbname+'","db_username":"'+dbuser+'","db_password":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  public getTarifesByCompany(dbname,dbuser,dbpass,compId){
    let url= this.getazBasePath();
    var body = JSON.parse('{"db_name":"'+dbname+'","db_username":"'+dbuser+'","db_password":"'+dbpass+'","company":"'+compId+'"}');
    return this.httpClient.post(url, body);
  }

  public uploadUsers(data): Observable<string> {
    let url = this.getBasePath() + "excel/";
    return this.httpClient.post(url, "aaa", { headers: this.getHeaders(), responseType: 'text' });
  }

  public getUsersExcel() {

    let url = this.getBasePath() + "ExcelUsr/";
    const type = 'application/vnd.ms-excel';

    return this.httpClient.get(url, {
      headers: this.getHeaders(),
      responseType: 'blob'
    }).map(
      res => {

        return new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      });

  }

  public deleteLogo(){
    let url= this.getfBasePath();
    var dbname = this.publicService.getDBName();
    var dbuser = this.publicService.getDBUser();
    var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  public getUsuaris(){
    let url= this.gethBasePath();
    var dbname = this.publicService.getDBName();
    var dbuser = this.publicService.getDBUser();
    var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  //public getFiles(id: string) {
  //    let url = this.getBasePath() + "files/"+id;
  //    return this.httpClient.get<User>(url, { headers: this.getHeaders() });
  //}
}
