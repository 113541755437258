 <div id="login_wrapper">
	<div class="logo">
        <a href="https://www.g2soft.com/" target="_blank">
            <img src="assets/img/LogoG2.png" alt="logo" class="logo-img" style="width: 94px; height: 94px;">
        </a>
	</div>
	<section id="login_content">
		<h1 class="login-title" style="color: #000; font-size: 35px; font-weight: bold; padding: 5%;">
			G2SGA
		</h1>
		<div class="login-body">
			<form class="form-horizontal" [formGroup]="formData" (ngSubmit)="onSubmit(formData.value)">
				 <div class="form-group row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <!-- style="max-width: 700px; color: #7E848C; font-weight: bold;  font-size: 16px !important; -->
                            <mat-label style="font-size: 16px;">Email</mat-label>
                            <input matInput type="email" formControlName="email" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label style="font-size: 16px;">Contraseña</mat-label>
                            <input matInput type="password" formControlName="password" required>
                        </mat-form-field>
                    </div>
                </div>

				<a href="javascript:void(0)" (click)="modalRestorePass.show()" class="forgot-pass float-right">Has olvidado la contraseña?</a>
				<div class="checkbox inline-block">
					<!--mat-checkbox name="remember" id='remember'>Recordar-me</mat-checkbox-->
				</div>
				<div class="login-options">
					<!-- <button mat-flat-button style="background-color: #007bff; color: #fff; border-radius: 15px !important; margin: 1% !important; width: 90%;">Entrar</button>    -->
					<button [disabled]="loading" style="background-color: #007bff; border-radius: 15px !important;" class="btn btn-info btn-block m-t-40" >Entrar</button>
				</div>
			</form> 
		</div>
	</section>
</div>
<!-- RECUPERAR CONTRASENYA -->
<div class="modal fade" bsModal #modalRestorePass="bs-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title float-left">Recuperar la contraseña</h4>
            	<mat-icon (click)="modalRestorePass.hide()" style="font-size: x-large;">close</mat-icon>
            </div>
 
            <div class="modal-body">
            	<div class="form-group row" style="justify-content: center; text-align: center;">
                    <div class="col-md-10">
		        		<mat-label>Entre su email de registro y se enviará un enlace para recuperar la contrasseña</mat-label>
		        	</div>
		        </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <mat-form-field>
		                    <mat-label style="font-size: 16px;">Email</mat-label>
		                    <input matInput type="email" required name="email" [(ngModel)]="emailRecover">
        				</mat-form-field>
                	</div>
                </div>
            </div>
            <!-- <div *ngIf="loadingEmail" class="loader loader-pink"></div> -->
            <div class="modal-footer">
            	<button [disabled]="isEmptyText()" mat-flat-button class="buttonG2" style="background-color: #007bff; color: #fff;" (click)="resendEmail()">Enviar</button>
              	<button mat-stroked-button color="warn" class="buttonG2" id="btnModal" (click)="modalRestorePass.hide()">Cancelar</button>
            </div>

        </div>
    </div>
</div>